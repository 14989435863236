
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import ContactUs from '@/components/contactUs/index.vue'
import {config} from "@/utils"
export default {
    name: 'monitor',
    components:{
        Header,
        Footer,
        ContactUs
    },
    mounted () {
    },
    created(){

    },
    methods: {
        changeStat(v){
            this.lNum=v
        },
        contactKefu(){
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }

        }
    },

    data(){
        return{
            lNum:1,


        }
        
    }
    
}